import React from "react";
import { graphql } from "gatsby";
import CategoriesView from "../../components/categories-view";
import Layout from "../../components/layout";

export const query = graphql`
  query Cat($slug: String!) {
    posts: allStrapiPost(
      limit: 20,
      sort: { order: DESC, fields: published_at }
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          slug
          title
          body
          excerpt
          published_at
          image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          categories {
            category
            slug
          }
          author {
          name
          id
        }
        }
      }
    }
    categories: strapiCategories(slug: { eq: $slug }) {
      category
      slug
    }
  }
`;

const Cat = ({ data }) => {
  const posts = data.posts.edges;
  const category = data.categories.category;

  return (
    <Layout>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl  sm:text-4xl">
            <span className="font-light text-gray-600">Kategorie:</span> <span className=" text-gray-900 font-extrabold">{category}</span>
          </h2>
          <div className="mt-6">
            <dl className="space-y-8 divide-y divide-gray-200">
              <CategoriesView posts={posts} />
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cat;
