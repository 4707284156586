import * as React from "react";
import { Link } from "gatsby";
import Moment from "react-moment";
import "moment/locale/de"
import Markdown from "react-markdown";

function CategoriesView({ posts }) {
  return (
    <>
      {posts.map((post) => (
        <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="text-base font-medium text-gray-900 md:col-span-5">
            <Link to={`/${post.node.slug}`}
            className="text-2xl font-bold text-gray-800">{post.node.title}</Link>
            <div className="mt-4 flex items-center">
              <div className="ml-0">
                <div className="flex space-x-1 text-sm text-gray-500">
                  <span className="capitalize">{post.node.author.name}</span>
                  <span aria-hidden="true">&middot;</span>
                  <time datetime={post.node.published_at}>
                    <Moment format="DD MMMM YYYY">
                      {post.node.published_at}
                    </Moment>
                  </time>
                </div>
              </div>
            </div>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base text-gray-500">
              <Markdown source={post.node.excerpt} escapeHtml={false} />
            </p>
          </dd>
        </div>
      ))}
    </>
  );
}

export default CategoriesView;
